import React from 'react'
import { graphql } from 'gatsby'
import { DebugLayout } from "../layouts/"
import { Typography } from "@mui/material"
import { LinkList, Note, CollapsibleSection, Block, BlockLinks, BlockLinksItem, BlockSection } from "../components/"
import { getNodes } from "../utils"

export const query = graphql`
    query DebugQuery {
        allSanityResource(sort: {fields: title, order: ASC}) {
            nodes {
                ...ResourceModule
            }
        }
        allSanityExercise(sort: {fields: title, order: ASC}) {
            nodes {
                ...ExerciseModule
            }
        }
        allSanityMethod(sort: {fields: title, order: ASC}) {
            nodes {
                ...MethodModule
            }
        }
        allSanityNote(sort: {fields: title, order: ASC}) {
            nodes {
                ...NoteModule
            }
        }
        allSanityExample(sort: {fields: title, order: ASC}) {
            nodes {
                ...ExampleModule
            }
        }
    }
`


const DebugPage = (props) => {

    const { data } = props

    const resources = data.allSanityResource && getNodes(data.allSanityResource)
    const methods = data.allSanityMethod && getNodes(data.allSanityMethod)
    const exercises = data.allSanityMethod && getNodes(data.allSanityExercise)
    const notes = data.allSanityNote && getNodes(data.allSanityNote)
    const examples = data.allSanityExample && getNodes(data.allSanityExample)

    return (
        <DebugLayout {...props} title="Debug" description="Ressurser, øvelser, metoder">

            <CollapsibleSection collapsible={true} title="Eksempler">
                { examples.map((item, index) => <BlockLinksItem {...item} key={index} />) }
            </CollapsibleSection>

            <CollapsibleSection collapsible={true} title="Ressurser">
                { resources.map((item, index) => <BlockLinksItem {...item} key={index} />) }
            </CollapsibleSection>

            <CollapsibleSection collapsible={true} title="Øvelser">
                { exercises.map((item, index) => <BlockLinksItem {...item} key={index} />) }
            </CollapsibleSection>

            <CollapsibleSection collapsible={true} title="Metoder">
                { methods.map((item, index) => <BlockLinksItem {...item} key={index} />) }
            </CollapsibleSection>

            <CollapsibleSection collapsible={true} title="Notiser">
                { notes.map((item, index) => <BlockLinksItem {...item} key={index} />) }
            </CollapsibleSection>


        </DebugLayout>
    )
    
}

export default DebugPage